import { createGlobalStyle } from "styled-components"
import fontFiles from "./fonts"


const globalStyle = createGlobalStyle`

    @font-face {
        font-family: "Fakedes";
        font-style: normal;
        font-weight: normal;
        font-display: fallback;
        src: local('\\2660'),
            url(${fontFiles.FakedesOutlineTTF}) format('ttf'),
            url(${fontFiles.FakedesOutlineEOT}) format('embedded-opentype'),
            url(${fontFiles.FakedesOutlineWOFF2}) format('woff2'),
            url(${fontFiles.FakedesOutlineWOFF}) format('woff');
    }
`
export default globalStyle
