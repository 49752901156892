import FakedesOutlineEOT from "../fonts/FakedesOutline.eot"
import FakedesOutlineTTF from "../fonts/FakedesOutline.ttf"
import FakedesOutlineWOFF from "../fonts/FakedesOutline.woff"
import FakedesOutlineWOFF2 from "../fonts/FakedesOutline.woff2"

export default {
    FakedesOutlineEOT,
    FakedesOutlineTTF,
    FakedesOutlineWOFF,
    FakedesOutlineWOFF2
};
