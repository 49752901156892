/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { GlobalState } from "./src/components/globalState.js"
import GlobalStyles from "./src/styles/globalStyles.js"
require('typeface-montserrat')
const { registerObserver } = require('react-perf-devtool')

// assign the observer to the global scope, as the GC will delete it otherwise
window.observer = registerObserver()
export const wrapRootElement = ({ element }) => {
    return (
        <GlobalState>
            <GlobalStyles />
            {element}
        </GlobalState>
    )
}
