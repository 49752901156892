module.exports = [{
      plugin: require('/Users/tasos/Documents/Repos/x40/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141736402-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/tasos/Documents/Repos/x40/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tasos/Documents/Repos/x40/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
